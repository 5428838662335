import React from 'react';

function TextShadow(props: {text: string; variant?: 'yellow' | 'blue'}) {
    let colorClass: string;

    switch (props.variant) {
        case 'blue':
            colorClass = 'text-noblue';
            break;
        default:
            colorClass = 'text-noyellow';
            break;
    }
    return (
        <>
            <div className='relative z-10'>{props.text}</div>
            <div
                className={
                    'absolute top-0 left-0 z-0 transform translate-x-0.75 translate-y-0.75 ' +
                    `${colorClass} no-selection`
                }
            >
                {props.text}
            </div>
        </>
    );
}

export default TextShadow;
