import React from 'react';
import TextShadow from '@components/elements/text-shadow';

function Footer() {
    return (
        <footer
            className={
                'bg-gray-900 w-full centering-col pt-8 pb-8 px-4 md:px-8 text-gray-300 text-center'
            }
        >
            <h3 className='relative mb-6 text-lg rubik'>
                <TextShadow
                    variant='blue'
                    text='Bündnis #noIAA - Klima vor Profit - Mobilitätswende jetzt'
                />
            </h3>
            <p className='mb-2 text-lg leading-tight crimson font-weight-300'>
                Vertreten durch:
            </p>
            <p className='mb-6 text-lg leading-tight crimson font-weight-500'>
                Ralf Schauer
                <br />
                c/o Attac München
                <br />
                Schwanthalerstr. 80 RGB
                <br />
                80336 München
            </p>
            <p className='mb-2 text-lg leading-tight crimson font-weight-300'>
                Kontakt:{' '}
                <a
                    className='underline font-weight-700'
                    href='mailto:website@noiaa.de'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    website@noiaa.de
                </a>
            </p>
        </footer>
    );
}

export default Footer;
