import React from 'react';
import Footer from './footer';

function Main(props: {
    children: React.ReactNode;
    slimHeader?: boolean;
    noBottom?: boolean;
}) {
    return (
        <>
            <main
                className={
                    'bg-gray-200 px-2 min-h-screen ' +
                    'centering-col z-0 relative ' +
                    (props.slimHeader ? 'pt-10 ' : 'pt-6 md:pt-10 ') +
                    (props.noBottom ? 'pb-0 md:pb-0 ' : 'pb-16 md:pb-24 ')
                }
            >
                {props.children}
            </main>
            <Footer />
        </>
    );
}

export default Main;
