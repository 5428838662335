import React from 'react';
import {Helmet} from 'react-helmet';

function Meta(props: {
    title: string;
    description: string;
    author?: string;
    type: string;
    path: string; // "/blog/..."
}) {
    return (
        <Helmet htmlAttributes={{lang: 'de'}}>
            <title>{props.title}</title>
            <meta name='description' content={props.description} />
            <meta property='og:title' content={props.title} />
            <meta property='og:description' content={props.description} />
            <meta property='og:image' content='/og-image.png' />
            <meta property='og:width' content='1200' />
            <meta property='og:height' content='630' />
            <meta property='og:type' content={props.type} />
            <meta property='og:url' content={'https://noiaa.de' + props.path} />
            <meta property='og:locale' content='de_DE' />
            <meta property='twitter:site' content='@no_iaa' />
            {props.author && <meta name='author' content={props.author} />}
            {props.author && (
                <meta property='og:article:author' content={props.author} />
            )}
            <style type='text/css'>{`
                .rubik {
                    font-family: 'Rubik', sans-serif;
                    letter-spacing: -0.05rem !important;
                }

                .crimson {
                    font-family: 'Crimson Pro', serif;
                }
            `}</style>
        </Helmet>
    );
}

export default Meta;
